
import { computed, defineComponent, inject, onMounted, ref } from 'vue'
import type { Ref } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueDragResize from 'vue-drag-resize'
import { panelPositionKey, itemIsResizableKey, parentWKey, parentHKey } from './MovablePanel.vue'
import { Position, Size, ComponentRect } from './type'
export default defineComponent({
  components: {
    VueDragResize
  },
  setup(props, context) {
    const container = ref<HTMLElement | null>(null)
    const component = ref('div')
    const size = ref<Size>({width: 0, height: 0})
    const position = ref<Position>({x: 0, y: 0})

    const panelPosition = inject<Ref<Position>>(panelPositionKey)
    const itemIsResizable = inject<Ref<boolean>>(itemIsResizableKey)
    const w = inject<Ref<number>>(parentWKey)
    const h = inject<Ref<number>>(parentHKey)

    const isResizable = computed(() => {
      return context.attrs.isResizable !== undefined ? context.attrs.isResizable :
        (itemIsResizable ? itemIsResizable.value : true)
    })

    const parentW = computed(() => {
      return context.attrs.parentW !== undefined ? context.attrs.parentW :
        (w ? w.value : 0)
    })

    const parentH = computed(() => {
      return context.attrs.parentH !== undefined ? context.attrs.parentH :
        (h ? h.value : 0)
    })

    onMounted(() => {
      const el = container.value
      if (el) {
        size.value.width = el.offsetWidth
        size.value.height = el.offsetHeight
        if (panelPosition) {
          position.value.x = el.offsetLeft - panelPosition.value.x
          position.value.y = el.offsetTop - panelPosition.value.y
        } else {
          position.value.x = el.offsetLeft
          position.value.y = el.offsetTop
        }
      }
      component.value = 'vue-drag-resize'
    })

    const doDragStop = ({left, top, width, height}: ComponentRect) => {
      position.value.x = left
      position.value.y = top
      size.value.width = width
      size.value.height = height
      context.emit('dragstop', {left, top, width, height})
    }

    return {
      container,
      component,
      size,
      position,
      isResizable,
      parentW,
      parentH,
      doDragStop
    }
  }
})
