import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-244ff39c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "container",
  style: {display: 'inline-block', textAlign: 'center'}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _mergeProps({
      w: _ctx.size.width,
      h: _ctx.size.height,
      x: _ctx.position.x,
      y: _ctx.position.y,
      isResizable: _ctx.isResizable,
      parentW: _ctx.parentW,
      parentH: _ctx.parentH,
      parentLimitation: true
    }, _ctx.$attrs, { onDragstop: _ctx.doDragStop }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 16, ["w", "h", "x", "y", "isResizable", "parentW", "parentH", "onDragstop"]))
  ], 512))
}