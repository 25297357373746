
import { defineComponent, onMounted, provide, ref, toRef, h, readonly } from "vue"
import type { Slot, PropType } from 'vue'
import type { Position } from './type'

export const panelPositionKey = Symbol()
export const itemIsResizableKey = Symbol()
export const parentWKey = Symbol()
export const parentHKey = Symbol()

export default defineComponent({
  props: {
    itemIsResizable: {
      type: Boolean,
      default: true
    },
    w: {
      type: Number,
      default: 0
    },
    h: {
      type: Number,
      default: 0
    },
    slotSort: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  render() {
    const children = []
    for (const name of this.$props.slotSort) {
      if (this.$slots[name]) {
        children.push((this.$slots[name] as Slot)())
      }
    }
    if (this.$slots.default) {
      children.push(this.$slots.default())
    }
    return h('div', {class: 'movable-panel', ref: 'panel'}, children)
  },
  setup(props) {
    const panel = ref<HTMLElement | null>(null)
    const position = ref<Position>({ x: 0, y: 0 })

    onMounted(() => {
      const el = panel.value
      if (el) {
        position.value.x = el.offsetLeft
        position.value.y = el.offsetTop
      }
    })

    provide(panelPositionKey, readonly(position))
    provide(itemIsResizableKey, readonly(toRef(props, 'itemIsResizable')))
    provide(parentWKey, readonly(toRef(props, 'w')))
    provide(parentHKey, readonly(toRef(props, 'h')))

    return {
      panel
    }
  }
})
